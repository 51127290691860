<template>
  <div>
    <EditDialog width="80%" :isReturn="true" @closeFn="$emit('bidCancel')" :isShow.sync="isShow">
      <div slot="title" class="title">关联投标信息</div>
      <div slot="content" class="content">
        <div>
          标的名称：
          <el-input
            style="width: 60%; margin: 0 8px 8px 0"
            v-model="parameter.bidName"
            placeholder="请输入标的名称"
          >
          </el-input>
          <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
            >搜索</el-button
          >
          <el-button type="warning" icon="el-icon-refresh" @click="handleReset"> 清空</el-button>
        </div>

        <el-table
          row-key="id"
          border
          tooltip-effect="dark"
          max-height="400"
          :data="tableData"
          v-loading="loading"
        >
          <el-table-column align="center" type="index" label="序号"></el-table-column>
          <el-table-column
            align="center"
            prop="bidName"
            min-width="280"
            :show-overflow-tooltip="false"
            label="标的名称"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="bidType"
            width="100"
            :show-overflow-tooltip="false"
            label="采购方式"
          >
            <template slot-scope="scope">
              {{ scope.row.bidType | dict(bidType) }}
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            width="70"
            :show-overflow-tooltip="false"
            prop="bidHostEscort"
            label="是否重点关注"
          >
            <template slot-scope="scope">
              {{ scope.row.bidHostEscort == 'IS_FLAG' ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            width="160"
            sortable
            prop="bidOpeningTime"
            label="开标时间"
          >
            <template slot-scope="scope">
              {{ scope.row.bidOpeningTime | timeFormat }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            :show-overflow-tooltip="false"
            min-width="220"
            prop="bidOwnerUnit"
            label="业主单位"
          ></el-table-column>
          <el-table-column
            align="center"
            :show-overflow-tooltip="false"
            min-width="220"
            prop="bidOwnerUnit"
            label="代理\招标单位"
          ></el-table-column>
          <el-table-column
            align="center"
            width="160"
            :show-overflow-tooltip="false"
            prop="bidWinningAmount"
            label="中标金额(元)"
          >
            <template slot-scope="scope">
              {{ scope.row.bidWinningAmount | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            min-width="160"
            :show-overflow-tooltip="false"
            prop="bidRemark"
            label="备注"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="bidStatus"
            label="状态"
            width="160"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              {{ scope.row.bidStatus | dict(bidStatus) }}
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="100" align="center">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="$emit('onSelectFn', scope.row)"
                :disabled="scope.row.id == bidSelectId"
                >{{ scope.row.id == bidSelectId ? '已选' : '选择' }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageChangeHandler"
          :current-page="parameter.pageNow"
          :page-size="parameter.pageSize"
          prev-text="上一页"
          next-text="下一页"
          layout="->,total, prev, pager, next, slot, jumper"
          :total="parameter.total"
        >
          <span class="el-pagination__jump e_a_pagination">
            <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
            <span style="padding-top: 1px">条/页</span>
          </span>
        </el-pagination>
      </div>
      <div slot="footer" class="footer">
        <el-button type="info" plain @click="$emit('bidCancel')">返回</el-button>
      </div>
    </EditDialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'SelectBidDialog',
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    bidSelectId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false, // 表格 加载
      // 查询条件
      parameter: {
        pageSize: 50,
        pageNow: 1,
        total: 0,
        bidName: null,
      },
      pageSize: 0,
      tableData: [], // 表格数据
      bidStatus: [], //投标状态
      bidType: [],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.$api.dict
      .listSysDictData('BID_STATUS', true)
      .then(res => {
        this.bidStatus = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BID_TYPE', true)
      .then(res => {
        this.bidType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.getBidData()
  },
  watch: {},
  methods: {
    async getBidData() {
      this.loading = true
      const { data } = await this.$api.bid.getBidList(this.parameter)
      if (data) {
        this.tableData = data.records
        this.parameter.total = data.total
      }
      this.loading = false
    },
    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.bidName = null
      this.parameter.pageSize = this.userInfo.pageSizeLog
      this.parameter.pageNow = 1
      this.getBidData()
    },
    /**
     * 点击 "表格 -- 分页" 操作
     * @param page      当前页
     */
    pageChangeHandler(page) {
      this.parameter.pageNow = page
      this.getBidData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getBidData()
    },
    handlePageSize() {
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getBidData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>

<style scoped lang="scss"></style>
